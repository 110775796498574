import { SessionReportInstructionType } from "@generated/graphql";
import { clsx } from "clsx";
import {
  EmptyStateContainer,
  Icon,
  SelectMenu,
  SelectMenuOption,
  TextArea,
} from "components/shared";
import React, { ReactNode, useMemo } from "react";
import {
  learningTargetOptions,
  priorityStandardOptions,
  strandOptions,
  subSectionStyles,
} from "../../../../../constants";
import { SessionModalPageSubHeader } from "../../../../../helpers";
import {
  InstructionData,
  VIMElaPriorityStandards,
  VIMMathStrands,
  WIMMathLearningTargets,
} from "../../../../../types";
import {
  infoKeySectionStyles,
  infoSectionStyles,
  infoValueSectionStyles,
} from "./constants";

type Props = {
  readOnly: boolean;
  allStudentsAbsent: boolean;
  highlightIncomplete: boolean;
  instructionData: InstructionData;
  instructionType: SessionReportInstructionType;
  setInstructionData: (data: InstructionData) => void;
};

export const InstructionSection = ({
  readOnly,
  instructionData,
  instructionType,
  allStudentsAbsent,
  highlightIncomplete,
  setInstructionData,
}: Props) => {
  const updateData = (data: Partial<InstructionData>) =>
    setInstructionData({ ...instructionData, ...data });

  const renderTextArea = (): ReactNode => {
    const { instructionNotes } = instructionData;

    return readOnly ? (
      <div className={infoSectionStyles}>
        <div className={infoKeySectionStyles}>Instruction Notes</div>
        <div className={clsx("flex flex-wrap", infoValueSectionStyles)}>
          {instructionNotes ? (
            instructionNotes
          ) : (
            <div className="text-gray-300">No instruction notes...</div>
          )}
        </div>
      </div>
    ) : (
      <TextArea
        id="notes"
        label="Notes"
        value={instructionNotes ?? ""}
        textAreaClassName="min-h-[38px]"
        className="flex flex-1 flex-grow"
        rows={!!instructionNotes ? 2 : 1}
        required={instructionType === SessionReportInstructionType.Default}
        onChange={(e) => updateData({ instructionNotes: e.target.value })}
      />
    );
  };

  const renderSelectMenu = (
    labelText: string,
    options: SelectMenuOption[],
    selectedValue: string | null,
    updateField: (value: ReactNode) => void
  ): ReactNode =>
    readOnly ? (
      <div className={infoSectionStyles}>
        <div className={infoKeySectionStyles}>{labelText}</div>
        <p className={infoValueSectionStyles}>{selectedValue}</p>
      </div>
    ) : (
      <SelectMenu
        required
        options={options}
        labelText={labelText}
        className="w-[250px]"
        inputClassName={clsx(
          highlightIncomplete &&
            selectedValue === null &&
            "border-2 border-red-500"
        )}
        initialIndex={options.findIndex(
          (option) => option.value === selectedValue
        )}
        onSelect={({ value }) => updateField(value === "--" ? null : value)}
      />
    );

  const instructionContent = useMemo(() => {
    const components: ReactNode[] = [];
    const { strand, learningTarget, classCode } = instructionData;

    switch (instructionType) {
      case SessionReportInstructionType.VimMath:
        components.push(
          renderSelectMenu("Strand", strandOptions, strand, (value) =>
            updateData({ strand: value as VIMMathStrands })
          )
        );
        components.push(
          renderSelectMenu(
            "Learning Target",
            learningTargetOptions,
            learningTarget,
            (value) =>
              updateData({ learningTarget: value as WIMMathLearningTargets })
          )
        );
        break;
      case SessionReportInstructionType.VimEla:
        components.push(
          renderSelectMenu(
            "Priority Standard",
            priorityStandardOptions,
            instructionData.priorityStandard,
            (value) =>
              updateData({ priorityStandard: value as VIMElaPriorityStandards })
          )
        );
        break;
      case SessionReportInstructionType.Vsup:
        components.push(
          readOnly ? (
            <div className={infoSectionStyles}>
              <p className={infoKeySectionStyles}>Class Code:</p>
              <p className={infoValueSectionStyles}>{classCode}</p>
            </div>
          ) : (
            <TextArea
              rows={1}
              required
              id="classCode"
              label="Class Code"
              className="w-[300px]"
              value={classCode ?? ""}
              textAreaClassName={clsx(
                "min-h-[38px] max-h-[38px]",
                highlightIncomplete &&
                  classCode === null &&
                  "border-2 border-red-500"
              )}
              onChange={(e) => updateData({ classCode: e.target.value })}
            />
          )
        );
        break;
      default:
        break;
    }

    return components;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instructionData, instructionType, highlightIncomplete]);

  return (
    <div className={clsx("flex flex-col gap-y-3 z-[300]", subSectionStyles)}>
      <SessionModalPageSubHeader
        title="Instruction"
        description={
          readOnly || allStudentsAbsent
            ? undefined
            : instructionType === SessionReportInstructionType.Vsup
            ? "Please enter your class code and provide a summary of what you taught in class today."
            : "What did you focus on in class today?"
        }
      />
      {allStudentsAbsent ? (
        <EmptyStateContainer
          title="No Instruction to Track"
          icon={<Icon icon="session" color="text-cyan-800" size={7} />}
          className="max-h-[120px]"
          subtitle="No students attended this session."
        />
      ) : (
        <div
          className={clsx(
            "flex flex-col w-full",
            readOnly ? "gap-y-[2px]" : "gap-y-1.5"
          )}
        >
          <div
            className={clsx(
              "flex gap-x-4",
              readOnly ? "flex-col gap-y-[2px]" : "flex-row"
            )}
          >
            {instructionContent.map((component, index) => (
              <React.Fragment key={index}>{component}</React.Fragment>
            ))}
          </div>
          {renderTextArea()}
        </div>
      )}
    </div>
  );
};
