import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { EmptyStateContainer, Icon } from "components/shared";
import { StudentEvaluationRow } from "components/shared/AttendanceGrades/SessionStudentEvaluationTable";
import { cloneDeep } from "lodash";
import { subSectionStyles } from "../../../../../constants";
import { SessionModalPageSubHeader } from "../../../../../helpers";
import {
  StudentPerformance,
  StudentPerformanceMap,
} from "../../../../../types";
import { StudentPerformanceTableHeader } from "./components/StudentPerformanceTableHeader";
import { StudentPerformanceTableRow } from "./components/StudentPerformanceTableRow";
import { initStudentPerformance } from "./constants";

type Props = {
  readOnly: boolean;
  highlightIncomplete: boolean;
  attendedStudents: StudentEvaluationRow[];
  studentPerformanceMap: StudentPerformanceMap;
  setStudentPerformanceMap: (data: StudentPerformanceMap) => void;
};

export const StudentPerformanceSection = ({
  readOnly,
  attendedStudents,
  highlightIncomplete,
  studentPerformanceMap,
  setStudentPerformanceMap,
}: Props) => {
  const hasRows = attendedStudents.length > 0;

  const updateData = (studentId: string, data: Partial<StudentPerformance>) => {
    const studentPerformanceMapCpy = cloneDeep(studentPerformanceMap);

    if (!studentPerformanceMapCpy[studentId])
      studentPerformanceMapCpy[studentId] = initStudentPerformance;
    studentPerformanceMapCpy[studentId] = {
      ...studentPerformanceMap[studentId],
      ...data,
    };
    setStudentPerformanceMap(studentPerformanceMapCpy);
  };

  const studentPerformanceTable = (
    <div
      className={clsx(
        "block w-full h-full overflow-x-auto overflow-y-hidden",
        getScrollbarStyle("barOnly")
      )}
    >
      <div
        className={clsx(
          "flex flex-col min-w-full w-fit rounded-lg border",
          "border-gray-300 divide-y divide-gray-300"
        )}
      >
        <StudentPerformanceTableHeader readOnly={readOnly} />

        {attendedStudents.map((row, i) => (
          <StudentPerformanceTableRow
            key={i}
            readOnly={readOnly}
            studentEvaluation={row}
            zIndex={attendedStudents.length - i}
            highlightIncomplete={highlightIncomplete}
            isLastRow={i == attendedStudents.length - 1}
            studentPerformance={studentPerformanceMap[row.studentId]}
            updateData={updateData}
          />
        ))}
      </div>
    </div>
  );

  return (
    <div className={clsx("flex flex-col gap-y-3", subSectionStyles)}>
      <SessionModalPageSubHeader title="Student Performance" />
      {hasRows ? (
        studentPerformanceTable
      ) : (
        <EmptyStateContainer
          title="No Student Performance to Track"
          icon={<Icon icon="session" color="text-cyan-800" size={7} />}
          className="max-h-[120px]"
          subtitle="No students attended this session."
        />
      )}
    </div>
  );
};
