import {
  CohortSessionStudentAttendanceStatus,
  TutoringTabCohortEventPanel_TutorDashboardCohortFragment,
  TutoringTabCohortEventPanel_TutorDashboardEngagementFragment,
  TutoringTabCohortEventPanel_TutorDashboardEventFragment,
} from "@generated/graphql";
import { EventScheduleStatus } from "types/events";

export type EventDetails = TutorTabDashboardEventDetails<
  TutoringTabCohortEventPanel_TutorDashboardEventFragment,
  TutoringTabCohortEventPanel_TutorDashboardCohortFragment,
  TutoringTabCohortEventPanel_TutorDashboardEngagementFragment
>;

export type TutorTabDashboardEventDetails<D, C, E> = D & {
  cohort: C | undefined;
  minutesElapsed: number;
  minutesRemaining: number;
  engagement: E | undefined;
  cohortSessionId?: string | null;
  scheduleStatus: EventScheduleStatus;
};

export enum SessionReportModalState {
  StudentEvaluation = "Student Attendance",
  TutorNotes = "Tutor Notes",
}

export type OnSaveStudentAttendanceProps = {
  status?: CohortSessionStudentAttendanceStatus;
  displayName?: string | null;
  notes?: string;
};
