import { gql, useMutation, useQuery } from "@apollo/client";
import {
  AddEditCohortSessionReportModalMutation,
  AddEditCohortSessionReportModalMutationVariables,
  GetCohortSessionModalReportQuery,
  GetCohortSessionModalReportQueryVariables,
  SessionReportInstructionType,
} from "@generated/graphql";
import { fetchErrToast } from "@utils/errorLogging";
import {
  Modal,
  OptionsToggleButton,
  triggerSuccessToast,
} from "components/shared";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { SessionReportModalState } from "../../../types";
import { initConcernData, initInstructionData } from "../constants";
import {
  InstructionData,
  SessionConcernData,
  StudentPerformanceMap,
} from "../types";
import {
  getSaveData,
  getStudentData,
  isInstructionDataValid,
  isStudentPerformanceValid,
  syncModalData,
} from "../utils";
import { StudentEvaluationModalBody, TutorNotesModalBody } from "./components";
import { ModalHeader } from "./components/TutorNotesModalBody/components/ModalHeader";
import { UserLogDetails } from "./components/TutorNotesModalBody/components/UserLogDetails";
import {
  COHORT_SESSION_DETAILS_FRAGMENT,
  COHORT_SESSION_EVALUATION_FRAGMENT,
  COHORT_SESSION_REPORT_FRAGMENT,
  COHORT_SESSION_STUDENTS_FRAGMENT,
} from "./fragments";

SessionReportModalBodyWrapper.fragments = {
  sessionReport: gql`
    fragment SessionReportModal_SessionReportModalData on CohortSessionReportModalData {
      id
      sessionDetails {
        ...SessionReportModal_CohortSessionReportDetails
      }
      evaluationDetails {
        ...SessionReportModal_CohortSessionReportEvaluation
      }
      studentRows {
        ...SessionReportModal_CohortSessionReportStudents
      }
      sessionReport {
        ...SessionReportModal_CohortSessionReport
      }
    }
    ${COHORT_SESSION_REPORT_FRAGMENT.cohortSessionReport}
    ${COHORT_SESSION_DETAILS_FRAGMENT.cohortSessionReport}
    ${COHORT_SESSION_STUDENTS_FRAGMENT.cohortSessionReport}
    ${COHORT_SESSION_EVALUATION_FRAGMENT.cohortSessionReport}
  `,
};

const GET_COHORT_SESSION_REPORT_MODAL_DATA = gql`
  query GetCohortSessionModalReport($cohortSessionId: ID!) {
    getSessionReportModalData(cohortSessionId: $cohortSessionId) {
      ...SessionReportModal_SessionReportModalData
    }
  }
  ${SessionReportModalBodyWrapper.fragments.sessionReport}
`;

const ADD_EDIT_COHORT_SESSION_REPORT_MODAL = gql`
  mutation AddEditCohortSessionReportModal(
    $input: AddEditSessionReportModalInput!
  ) {
    cohortSessionReportModal {
      addEditModal(input: $input) {
        id
        studentPerformance {
          id
        }
        sessionConcerns {
          id
        }
      }
    }
  }
`;

type Props = {
  readOnly: boolean;
  cohortSessionId: string;
  sessionReportId: string | null;
  onClose: () => void;
  setModalHeader: (header: ReactNode) => void;
};

export function SessionReportModalBodyWrapper({
  cohortSessionId,
  sessionReportId,
  readOnly = false,
  onClose,
  setModalHeader,
}: Props) {
  const firstDataLoad = useRef(true);
  const firstEvaluationCheck = useRef(true);
  const { StudentEvaluation } = SessionReportModalState;
  const [modalState, setModalState] =
    useState<SessionReportModalState>(StudentEvaluation);

  const [concernsData, setConcernsData] =
    useState<SessionConcernData>(initConcernData);

  const [instructionData, setInstructionData] =
    useState<InstructionData>(initInstructionData);

  const [studentPerformanceMap, setStudentPerformanceMap] =
    useState<StudentPerformanceMap>({});

  const closeModal = () => {
    onClose();
    setTimeout(() => {
      setStudentPerformanceMap({});
      setConcernsData(initConcernData);
      setInstructionData(initInstructionData);
    }, 500);
  };

  const { data, loading, refetch } = useQuery<
    GetCohortSessionModalReportQuery,
    GetCohortSessionModalReportQueryVariables
  >(GET_COHORT_SESSION_REPORT_MODAL_DATA, {
    variables: { cohortSessionId },
    fetchPolicy: "cache-and-network",
    onError: (error) => fetchErrToast("the selected engagement.", error),
    onCompleted: ({ getSessionReportModalData: report }) =>
      syncModalData(
        setInstructionData,
        setConcernsData,
        setStudentPerformanceMap,
        report?.sessionReport
      ),
  });

  const info = data?.getSessionReportModalData;
  const { Default } = SessionReportInstructionType;
  const instructionType = info?.sessionDetails.instructionType ?? Default;
  const { evaluationDetails, sessionReport, sessionDetails } = info || {};

  useEffect(() => {
    if (sessionDetails && firstDataLoad.current) {
      setModalHeader(
        <ModalHeader
          readOnly={readOnly}
          sessionReport={sessionReport}
          sessionDetails={sessionDetails}
        />
      );
      firstDataLoad.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [addEditCohortSessionReport] = useMutation<
    AddEditCohortSessionReportModalMutation,
    AddEditCohortSessionReportModalMutationVariables
  >(ADD_EDIT_COHORT_SESSION_REPORT_MODAL, {
    update: (cache, { data }) => {
      const { addEditModal } = data?.cohortSessionReportModal || {};

      refetch();
      if (!addEditModal) return;
      const { studentPerformance, id } = addEditModal;

      cache.modify({
        id: `TutorDashboardCohortSession:${cohortSessionId}`,
        fields: {
          sessionReportId: () => id,
          sessionReportStudentPerformanceCount: () =>
            (studentPerformance ?? []).length,
        },
      });
    },
    onCompleted: () => {
      onClose();
      triggerSuccessToast({
        message: "Session Report Saved",
        sub: "Cohort Session Report has successfully been saved!",
      });
    },
  });

  const { attendedStudents, studentEvaluationRows } = useMemo(() => {
    return getStudentData(info?.studentRows, evaluationDetails, sessionDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const instructionDataIsComplete: boolean = useMemo(() => {
    const noStudentsAttended = attendedStudents.length === 0;
    const isValid = isInstructionDataValid(instructionData, instructionType);
    return noStudentsAttended || isValid;
  }, [attendedStudents.length, instructionData, instructionType]);

  const studentPerformanceIsComplete: boolean = useMemo(() => {
    return isStudentPerformanceValid(studentPerformanceMap, attendedStudents);
  }, [attendedStudents, studentPerformanceMap]);

  const allEvaluationsComplete: boolean = useMemo(() => {
    return (studentEvaluationRows ?? []).every((e) => e.isEvaluationComplete);
  }, [studentEvaluationRows]);

  useEffect(() => {
    if (allEvaluationsComplete !== undefined) {
      if (firstEvaluationCheck.current && data) {
        setModalState(
          allEvaluationsComplete && sessionReportId !== null
            ? SessionReportModalState.TutorNotes
            : SessionReportModalState.StudentEvaluation
        );
      }
      firstEvaluationCheck.current = false;
    }
  }, [allEvaluationsComplete, data, sessionReportId]);

  const canSave = useMemo(() => {
    const { concerns, hasConcerns } = concernsData;
    if (loading) return false;
    if (hasConcerns === null) return false;
    if (!allEvaluationsComplete) return false;
    if (!instructionDataIsComplete) return false;
    if (!studentPerformanceIsComplete) return false;
    if (hasConcerns && concerns.length === 0) return false;
    return true;
  }, [
    loading,
    concernsData,
    allEvaluationsComplete,
    instructionDataIsComplete,
    studentPerformanceIsComplete,
  ]);

  const saveSessionReport = () => {
    if (cohortSessionId && canSave) {
      addEditCohortSessionReport({
        variables: getSaveData(
          sessionReportId,
          cohortSessionId,
          instructionData,
          concernsData,
          attendedStudents,
          studentPerformanceMap
        ),
      });
    }
  };

  if (!sessionDetails || !evaluationDetails) return null;

  return (
    <div className="flex flex-col px-3">
      {readOnly && (
        <OptionsToggleButton
          className="mt-5 mb-8"
          titleClassName="text-xl font-bold"
          options={Object.values(SessionReportModalState).map((type) => type)}
          activeTab={Object.values(SessionReportModalState).indexOf(modalState)}
          setActiveTab={(i) =>
            setModalState(Object.values(SessionReportModalState)[i])
          }
        />
      )}

      {modalState === StudentEvaluation ? (
        <StudentEvaluationModalBody
          readOnly={readOnly}
          evaluationDetails={evaluationDetails}
          studentEvaluationRows={studentEvaluationRows}
          allEvaluationsComplete={allEvaluationsComplete}
          onClose={closeModal}
          onConfirm={() => setModalState(SessionReportModalState.TutorNotes)}
        />
      ) : (
        <TutorNotesModalBody
          readOnly={readOnly}
          canSave={canSave}
          concernsData={concernsData}
          instructionType={instructionType}
          instructionData={instructionData}
          attendedStudents={attendedStudents}
          activeStudents={studentEvaluationRows}
          allEvaluationsComplete={allEvaluationsComplete}
          studentPerformanceMap={studentPerformanceMap}
          onClose={closeModal}
          onSave={saveSessionReport}
          setConcernsData={setConcernsData}
          setInstructionData={setInstructionData}
          onGoBack={() => setModalState(StudentEvaluation)}
          setStudentPerformanceMap={setStudentPerformanceMap}
        />
      )}

      {readOnly && (
        <Modal.Buttons className="flex w-full items-end justify-between z-0 !mt-0 pt-5">
          <Modal.Button type="cancel" onClick={closeModal}>
            Close
          </Modal.Button>
          <UserLogDetails
            className="pl-3 !gap-y-1"
            showDates={true}
            sessionReport={sessionReport}
          />
        </Modal.Buttons>
      )}
    </div>
  );
}
