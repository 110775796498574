import {
  nonTableTitleTextStyles,
  tableTitleTextStyles,
} from "@utils/styleStrings";
import clsx from "clsx";
import { Icon, Tooltip } from "components/shared";
import { useLayout } from "contexts/LayoutProvider";
import {
  ATTENDANCE_W,
  cellStyles,
  NAME_W,
  PERFORMANCE_MIN_W,
  SCALE_READONLY_W,
  SCALE_W,
} from "../constants";

type Props = { readOnly: boolean };

export const StudentPerformanceTableHeader = ({ readOnly }: Props) => {
  const { isLessThan3XlScreen } = useLayout();
  const mergeNotesColumn = isLessThan3XlScreen && !readOnly;

  const lastCols = (
    <>
      <div
        className={clsx(
          cellStyles,
          "relative",
          !mergeNotesColumn && "justify-center"
        )}
        style={{
          width: mergeNotesColumn
            ? "50%"
            : `${readOnly ? SCALE_READONLY_W : SCALE_W}px`,
        }}
      >
        <Tooltip
          className="relative flex items-center cursor-pointer gap-x-1"
          content={
            <div className={nonTableTitleTextStyles}>
              {`Did the student achieve today's learning goals?`}
            </div>
          }
        >
          Mastery
          <Icon size={3} className="absolute -top-[3px] -right-[14px]" />
        </Tooltip>
      </div>

      <div
        className={clsx(cellStyles, !mergeNotesColumn && "justify-center")}
        style={{
          width: mergeNotesColumn
            ? "50%"
            : `${readOnly ? SCALE_READONLY_W : SCALE_W}px`,
        }}
      >
        <Tooltip
          className="relative flex items-center cursor-pointer gap-x-1"
          content={
            <div className={nonTableTitleTextStyles}>
              {`Did the student respond and interact throughout the lesson?`}
            </div>
          }
        >
          Engagement
          <Icon size={3} className="absolute -top-[3px] -right-[14px]" />
        </Tooltip>
      </div>

      {!mergeNotesColumn && (
        <div className={clsx(cellStyles, "flex-1 flex-grow p-0")}>
          Shout outs
        </div>
      )}
    </>
  );

  return (
    <div
      className={clsx(
        "flex w-full h-[42px] bg-slate-100/80 rounded-t-lg items-center",
        tableTitleTextStyles
      )}
    >
      <div className={cellStyles} style={{ width: `${NAME_W}px` }}>
        Student
      </div>

      <div
        className={clsx(cellStyles, "justify-center")}
        style={{ width: `${ATTENDANCE_W}px` }}
      >
        Attendance
      </div>

      {mergeNotesColumn ? (
        <div
          className={clsx(cellStyles, "flex-1 flex-grow p-0")}
          style={{ width: `${PERFORMANCE_MIN_W}px` }}
        >
          {lastCols}
        </div>
      ) : (
        lastCols
      )}
    </div>
  );
};
